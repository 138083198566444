<script>
  import LogoWithTitle from "./LogoWithTitle.svelte";
</script>

<div
  class="inline inset large"
  style="width: 100%; justify-content:space-between"
>
  <span></span>
  <LogoWithTitle />
  <span></span>
</div>
