<script lang="ts">
  import { signUp } from "aws-amplify/auth";

  let email = "";
  let password = "";

  async function signupWithEmail() {
    const response = await signUp({
      username: email,
      password: password,
      options: {
        userAttributes: {
          email,
        },
      },
    });
    console.log(response);
  }
</script>

<h1>Sign Up</h1>
<div>
  <label for="email">Email</label>
  <input bind:value={email} name="email" type="email" />
  <input bind:value={password} name="password" type="password" />
  <button on:click={signupWithEmail}>Sign Up</button>
</div>
