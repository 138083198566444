import type { SvelteComponent } from "svelte";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
if (!(globalThis as any).URLPattern) {
  await import("urlpattern-polyfill");
}

type Route<T> = {
  title: string | ((context: RoutingContext<T>) => string);
  path?: string;
  component: typeof SvelteComponent<{ page?: RoutingContext<T> }>;
  redirect?: (context: RoutingContext<T>) => string | undefined | void;
};
export type RoutingContext<T> = T & {
  params: Record<string, string | undefined>;
  query: Record<string, string>;
  url: URL;
};
type ResultingRoute<T> = {
  route: Route<T>;
  context: RoutingContext<T>;
};

export const navigateTo = (
  url: string,
  options: { replace?: boolean } = {},
) => {
  const urlObj = new URL(url, window.location.href);
  if (options?.replace) {
    window.history.replaceState(
      null,
      "",
      `${urlObj.pathname}${urlObj.search}${urlObj.hash}`,
    );
  } else {
    window.history.pushState(
      null,
      "",
      `${urlObj.pathname}${urlObj.search}${urlObj.hash}`,
    );
  }
  window.dispatchEvent(new PopStateEvent("popstate"));
};

export const createRouter = <T>({
  routes,
  window: win = window,
  default: def,
  root,
}: {
  routes: Route<T>[];
  default: string;
  window?: typeof window;
  root: HTMLElement;
}): { updateContext: (context: T) => void; stop: () => void } => {
  let context: T;

  const routesData = routes.map((route) => ({
    ...route,
    urlPattern: new URLPattern({
      pathname: route.path,
      baseURL: window.location.href,
      search: "*",
      hash: "*",
    }),
  }));
  const defaultRoute = routes.find((route) => route.path === def);
  if (!defaultRoute) {
    throw new Error(`Default route not found: ${def}`);
  }

  const matchRoute = (url: URL): ResultingRoute<T> => {
    for (const route of routesData) {
      const match = route.urlPattern.exec(url);
      if (match) {
        return {
          route,
          context: {
            url,
            params: match?.pathname?.groups ?? {},
            query: Object.fromEntries(new URLSearchParams(url.search)),
            ...context,
          },
        };
      }
    }
    return {
      route: defaultRoute,
      context: {
        url,
        params: {},
        query: {},
        ...context,
      },
    };
  };

  const navigate = (
    url: URL,
    options: { replace?: boolean; backNav?: boolean } = {},
  ) => {
    const { route, context } = matchRoute(url);
    const newPath = route.redirect?.(context);
    if (newPath) {
      navigateTo(newPath, options);
      return;
    }

    if (options?.replace) {
      window.history.replaceState(
        null,
        "",
        `${url.pathname}${url.search}${url.hash}`,
      );
    } else if (!options.backNav) {
      window.history.pushState(
        null,
        "",
        `${url.pathname}${url.search}${url.hash}`,
      );
    }

    document.title =
      typeof route.title === "function" ? route.title(context) : route.title;

    root.innerHTML = "";
    new route.component({
      target: root,
      props: { page: context },
    });
  };

  const onPopState = () => {
    navigate(new URL(window.location.href), { backNav: true });
  };

  return {
    updateContext: (newContext: T) => {
      const oldContext = context;
      context = newContext;
      if (oldContext === undefined) {
        win.addEventListener("popstate", onPopState);
        win.addEventListener("load", onPopState);
      }
      onPopState();
    },
    stop: () => {
      win.removeEventListener("popstate", onPopState);
      win.removeEventListener("load", onPopState);
    },
  };
};
