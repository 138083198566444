<script>
  import Logo from "./Logo.svelte";
</script>

<span class="inline small" {...$$restProps}>
  <span style="margin-bottom: -6px ;font-size: var(--font-size-xlarge)"
    >DuoDo</span
  >
  <Logo size="medium" />
</span>
