import type { Opaque } from "./types.ts";

export type EpochTimestamp = Opaque<number, "timestamp">;

export const getEpochTimestamp = (date: Date): EpochTimestamp =>
  date.getTime() as EpochTimestamp;

export const newTimestamp = (): EpochTimestamp => Date.now() as EpochTimestamp;

const units: [Intl.RelativeTimeFormatUnit, EpochTimestamp][] = [
  ["year", (24 * 60 * 60 * 1000 * 365) as EpochTimestamp],
  ["month", ((24 * 60 * 60 * 1000 * 365) / 12) as EpochTimestamp],
  ["day", (24 * 60 * 60 * 1000) as EpochTimestamp],
  ["hour", (60 * 60 * 1000) as EpochTimestamp],
  ["minute", (60 * 1000) as EpochTimestamp],
  ["second", 1000 as EpochTimestamp],
];

const relativeTimeFormat = new Intl.RelativeTimeFormat(undefined, {
  numeric: "auto",
});
export const formatRelativeTime = (dateA: Date, dateB = new Date()): string => {
  const elapsed = dateA.getTime() - dateB.getTime();

  for (const [unit, value] of units)
    if (Math.abs(elapsed) > value)
      return relativeTimeFormat.format(Math.round(elapsed / value), unit);

  return relativeTimeFormat.format(Math.round(elapsed / 1000), "second");
};

export const formatDate = new Intl.DateTimeFormat(undefined, {
  year: "numeric",
  month: "short",
  day: "numeric",
}).format;

export const formatDateTime = new Intl.DateTimeFormat(undefined, {
  year: "numeric",
  month: "short",
  day: "numeric",
  hour: "2-digit",
  minute: "2-digit",
}).format;

export const formatTime = new Intl.DateTimeFormat(undefined, {
  hour: "2-digit",
  minute: "2-digit",
  second: "2-digit",
}).format;

export const dateRegex = /^\d{4}-\d{2}-\d{2}$/;

export const dateTimeRegex =
  /^\d{4}-\d{2}-\d{2}(T\d{2}:\d{2}:\d{2}(\.\d{3})?)?$/;

export const dateString = (date: Date) => date.toISOString().slice(0, 10);
export const dateTimeString = (date: Date) => date.toISOString().slice(0, 19);
export const millisInDay = 24 * 60 * 60 * 1000;

export type EpochDate = Opaque<number, "date">;
export const getEpochDateFromTime = (time: EpochTimestamp): EpochDate =>
  Math.floor(time / millisInDay) as EpochDate;

export const getEpochTimeFromDate = (date: EpochDate): EpochTimestamp =>
  (date * millisInDay) as EpochTimestamp;
export const getEpochDate = (date: Date): EpochDate =>
  getEpochDateFromTime(
    Date.UTC(
      date.getFullYear(),
      date.getMonth(),
      date.getDate(),
    ) as EpochTimestamp,
  );

export const getLocalDateFromEpochDate = (date: EpochDate): Date => {
  const utcDate = new Date(date * millisInDay);
  return new Date(utcDate.getTime() + utcDate.getTimezoneOffset() * 60 * 1000);
};

export const getUTCDateFromEpochDate = (date: EpochDate): Date =>
  new Date(date * millisInDay);
