import {
  hexToBinary,
  base64UriToBinary,
  binaryToBase64Uri,
  binaryToHex,
} from "./encoding.ts";
import type { HexString } from "./encoding.ts";

export type UuidString = string;
export type UuidBinary = Uint8Array;
export type UuidBase64 = string;
export const createUuid = (): UuidString => crypto.randomUUID();

export const createUuidBase64 = (): UuidBase64 =>
  uuidStringToBase64(createUuid());

export const canBeBase64Uuid = (uuid: unknown): uuid is UuidBase64 =>
  typeof uuid === "string" && uuid.length === 22;

const uuidRegExp =
  /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/;
export const isUuidString = (uuid: unknown): uuid is UuidString => {
  if (typeof uuid !== "string") return false;
  return uuidRegExp.test(uuid);
};

export const uuidToHex = (uuid: UuidString) => uuid.replace(/-/g, "");

const hexToUuid = (hex: HexString) =>
  hex.replace(/(.{8})(.{4})(.{4})(.{4})(.{12})/, "$1-$2-$3-$4-$5");

export const uuidBinaryToBase64 = (uuid: UuidBinary): UuidBase64 =>
  binaryToBase64Uri(uuid);

export const uuidBinaryToString = (uuid: UuidBinary): UuidString =>
  hexToUuid(binaryToHex(uuid));

export const uuidStringToBinary = (uuid: UuidString): UuidBinary =>
  hexToBinary(uuidToHex(uuid));
export const uuidStringToBase64 = (uuid: UuidString): UuidBase64 =>
  uuidBinaryToBase64(uuidStringToBinary(uuid));

export const uuidBase64ToBinary = (uuid: UuidBase64): UuidBinary =>
  base64UriToBinary(uuid);

export const uuidBase64ToString = (uuid: UuidBase64): UuidString =>
  hexToUuid(binaryToHex(uuidBase64ToBinary(uuid)));

type UuidV7Generator = () => UuidBinary;

/**
 * Generates sortable uuids in version 7 based on
 * https://www.ietf.org/archive/id/draft-peabody-dispatch-new-uuid-format-04.html#monotonicity_counters
 *
 * @param getTimestamp returns the current timestamp in milliseconds
 * @param getRandomList returns a Uint8Array of length 16 filled with random values
 * @param rollbackAllowance the number of milliseconds to allow for the system clock to roll back. Generating multiple uuids in a millisecond moves the clock forward.
 */
export const createUuidV7Generator = (
  getTimestamp = Date.now,
  getRandomList = () => crypto.getRandomValues(new Uint8Array(16)),
  rollbackAllowance = 10_000,
): UuidV7Generator => {
  let timestamp = 0;
  return () => {
    const currentTs = getTimestamp();
    if (currentTs > timestamp) {
      timestamp = currentTs;
    } else if (currentTs + rollbackAllowance >= timestamp) {
      timestamp += 1;
    } else {
      throw new Error(
        "System clock is out of sync, or generated more uuids in a millisecond over allowed rate",
      );
    }
    const bytes = getRandomList();
    bytes[0] = timestamp / 2 ** 40;
    bytes[1] = timestamp / 2 ** 32;
    bytes[2] = timestamp / 2 ** 24;
    bytes[3] = timestamp / 2 ** 16;
    bytes[4] = timestamp / 2 ** 8;
    bytes[5] = timestamp;
    bytes[6] = 0x70 | (bytes[6] & 0x0f); // // set version field, top four bits are 0, 1, 1, 1
    bytes[8] = 0x80 | (bytes[8] & 0x3f); // set variant field, top two bits are 1, 0
    return bytes;
  };
};

export const UUID_BYTE_LENGTH = 16;
export const uuidBinaryEqual = (a: UuidBinary, b: UuidBinary): boolean => {
  for (let i = 0; i < UUID_BYTE_LENGTH; i++) {
    if (a[i] !== b[i]) return false;
  }
  return true;
};
