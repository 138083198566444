<script lang="ts">
  import { signIn } from "aws-amplify/auth";

  let email = "";
  let password = "";

  async function signupWithEmail() {
    const response = await signIn({
      username: email,
      password: password,
    });
    console.log(response);
  }
</script>

<h1>Sign In</h1>
<div>
  <label for="email">Email</label>
  <input bind:value={email} name="email" type="email" />
  <input bind:value={password} name="password" type="password" />
  <button on:click={signupWithEmail}>Sign In</button>
</div>
