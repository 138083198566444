import type { ResultAsync } from "@duodo/utils/result";
import { err, fromPromise, isErr, ok } from "@duodo/utils/result";

import { HttpStatus } from "../../functions/utils/response.ts";
import type { AuthTokenProvider } from "../model/session.ts";

export type Page<T> = {
  values: T[];
};
export type ErrorMessage = {
  errorKey: string;
};

export type ApiResult<T> = ResultAsync<T, ErrorMessage>;

type ApiFetchOpts<Req, Res> = {
  method: "GET" | "POST" | "PUT" | "DELETE" | "PATCH";
  body: Req extends void ? undefined : Req;
  parseResponse?: (data: string) => Res;
  authProvider?: AuthTokenProvider;
};

export const handleFetchErrors = (error: unknown): ErrorMessage => {
  console.error(error);
  return {
    errorKey: "network-error",
  };
};

export const handleFetchResponse = async <T>(
  response: Response,
  { parseResponse }: { parseResponse?: (data: string) => T } = {},
): ApiResult<T> => {
  if (response.status === 204) {
    return err({ errorKey: "NO-CONTENT" });
  }
  if (response.status === 200 || response.status === 201) {
    const contentType = response.headers.get("Content-Type") ?? "";
    if (contentType === "application/json") {
      return ok(await response.json());
    }
    if (contentType === "text/plain") {
      return ok((await response.text()) as T);
    }
    if (parseResponse) {
      return ok(parseResponse(await response.text()));
    }
    return err({ errorKey: "unsupported-content-type" });
  }
  if (response.status === HttpStatus.BadRequest) {
    return err(await response.json());
  }
  return err({
    errorKey: "unsupported-status-code-" + response.status,
  });
};

export const handleEmptyFetchResponse = async (
  response: Response,
): ApiResult<undefined> => {
  if (response.status === HttpStatus.Empty) {
    return ok(undefined);
  }
  if (response.ok) {
    return err({ errorKey: "expected-no-content" });
  }
  if (response.status === HttpStatus.BadRequest) {
    return err(await response.json());
  }
  return err({
    errorKey: "unsupported-status-code-" + response.status,
  });
};

export const apiFetch = async <Req, Res>(
  path: string,
  options: ApiFetchOpts<Req, Res>,
) => {
  let token: string | undefined;
  if (options.authProvider) {
    const tokenRes = await options.authProvider();
    if (isErr(tokenRes))
      return err({
        errorKey: "TOKEN_ERROR",
      });
    token = tokenRes.value;
  }

  return fromPromise(
    fetch(`api/${path}`, {
      method: options.method,
      body:
        options.body === undefined ? undefined : JSON.stringify(options.body),
      headers: {
        ...(token ? { Authorization: token } : {}),
        ...(options.body === undefined
          ? {}
          : { "Content-Type": "application/json" }),
      },
    }),
    handleFetchErrors,
  );
};

export const apiRequest = async <Req, Res>(
  path: string,
  options: ApiFetchOpts<Req, Res>,
): ApiResult<Res> => {
  const responseResult = await apiFetch(path, options);
  if (isErr(responseResult)) return responseResult;
  const response = responseResult.value;
  return handleFetchResponse(response, options);
};

export const apiRequestEmptyResponse = async <Req>(
  path: string,
  options: ApiFetchOpts<Req, void>,
): ApiResult<void> => {
  const responseResult = await apiFetch(path, options);
  if (isErr(responseResult)) return responseResult;
  const response = responseResult.value;
  return handleEmptyFetchResponse(response);
};
