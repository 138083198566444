import { createUuidBase64 } from "@duodo/utils/uuid.ts";

import type { SpaceId } from "../model/space";
import type { TasksStore } from "../model/task-store";
import { createInMemoryTaskStore } from "../model/task-store";
import { mockTask, mockDoneTask } from "../model/task.mock";
import type { UserId } from "../model/user";

export type Datastore = {
  addSpace: (name: string) => Promise<SpaceId>;
  shareSpace: (user: UserId, space: SpaceId) => Promise<void>;
  getSpaces: () => Promise<SpaceId[]>;
  tasks: (space: SpaceId) => TasksStore;
};

export const createInMemoryDatastore = (author: UserId): Datastore => {
  let lastSpace: SpaceId | undefined;
  let lasTaskStore: TasksStore | undefined;
  return {
    addSpace: async (name) => {
      const data = {
        name,
        author,
        contributors: [author],
      };
      // eslint-disable-next-line no-console
      console.log(data);
      return createUuidBase64() as SpaceId;
    },
    shareSpace: async () => {
      // const docRef = collection(db, "space", space);
      // await docRef.update({
      //   contributors: [user],
      // });
    },
    getSpaces: async () => {
      return [];
    },
    tasks: (space) => {
      if (space !== lastSpace || lasTaskStore === undefined) {
        lastSpace = space;
        lasTaskStore = createInMemoryTaskStore(author, [mockTask, mockDoneTask]);
      }
      return lasTaskStore;
    },
  };
};
