<script lang="ts">
  import { createEventDispatcher } from "svelte";

  import { newTimestamp } from "../../packages/utils/time.ts";
  import type { Task } from "../model/task";
  import type { TaskEvent } from "../model/task-store.ts";

  export let task: Task;
  const dispatch = createEventDispatcher<{ update: TaskEvent }>();

  const edit = (e: Event) =>
    dispatch("update", {
      id: task.id,
      type: "update-name",
      name: (e.target as HTMLInputElement).value,
      updatedAt: newTimestamp(),
    });

  const toggle = () =>
    dispatch("update", {
      id: task.id,
      type: task.status === "done" ? "todo" : "done",
      updatedAt: newTimestamp(),
    });
</script>

<div class="task">
{#if task.status === "done"}
  <span role="listitem" class="done" on:dblclick={toggle}>{task.name}</span>
{:else}
  <input on:dblclick={toggle} on:change={edit} value={task.name} />
{/if}
</div>

<style>
  input {
    border: none;
    background: none;
    outline: none;
    padding: 0;
  }

  .done {
    text-decoration: line-through;
    user-select: none;
  }

  .task {
    background: var(--color-milk-blue);
    padding: var(--space-s);
    border-radius: 4px;
  }
</style>
