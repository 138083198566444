import {
  err,
  isErr,
  isOk,
  ok,
  throwIfError,
} from "../../packages/utils/result.ts";
import {
  createSpaceSession,
  refreshSession,
  verify,
} from "../backend/sessions-fetch.ts";
import type {
  AuthTokenProvider,
  SpaceSession,
  UserSessionTokens,
  UserTokenProvider,
} from "../model/session.ts";
import {
  isAboutToExpire,
  isExpired,
  readSpaceSessionToken,
  readUserSessionToken,
} from "../model/session.ts";
import type { SpaceId } from "../model/space.ts";

export const createAuthProvider = (
  { sessionToken, refreshToken }: UserSessionTokens,
  saveSession: (session: UserSessionTokens) => void,
  onSessionExpired: () => void,
): AuthTokenProvider => {
  let userSession = throwIfError(readUserSessionToken(sessionToken));
  const getUserSessionToken: UserTokenProvider = async () => {
    if (isExpired(userSession)) {
      onSessionExpired();
      return err("token-expired");
    }
    if (isAboutToExpire(userSession)) {
      const res = await refreshSession({ refreshToken });
      if (isErr(res)) {
        return err(res.error.errorKey);
      }
      const sessionToken = res.value.sessionToken;
      userSession = throwIfError(readUserSessionToken(sessionToken));
      refreshToken = res.value.refreshToken;
      saveSession({ sessionToken, refreshToken });
    }
    return ok(userSession.token);
  };

  const spaceSessions = new Map<SpaceId, SpaceSession>();

  return async (space?: SpaceId) => {
    if (space === undefined) {
      return getUserSessionToken();
    }

    const spaceSession = spaceSessions.get(space);
    if (spaceSession && !isExpired(spaceSession)) {
      return ok(spaceSession.token);
    }
    const spaceToken = await createSpaceSession(space, getUserSessionToken);
    if (isErr(spaceToken)) {
      return err(spaceToken.error.errorKey);
    }
    const newSpaceSession = throwIfError(
      readSpaceSessionToken(spaceToken.value),
    );
    spaceSessions.set(space, newSpaceSession);

    return ok(newSpaceSession.token);
  };
};

export type AuthEvent =
  | { type: "not-signed" }
  | {
      type: "signed-in";
      authProvider: AuthTokenProvider;
    }
  | { type: "signed-out" };

const storageSessionTokensKey = "session";
const readSessionTokens = (): UserSessionTokens | null => {
  const tokens = localStorage.getItem(storageSessionTokensKey);
  if (tokens === null) return null;
  return JSON.parse(tokens) as UserSessionTokens;
};
const saveSessionTokens = (tokens: UserSessionTokens) => {
  localStorage.setItem(storageSessionTokensKey, JSON.stringify(tokens));
};
const readVerifyToken = (url: string): string | null => {
  const urlObj = new URL(url);
  if (!urlObj.searchParams.has("verify")) return null;
  const hashParam = (urlObj.hash ?? "").split("=");
  if (hashParam.length !== 2 || hashParam[0] !== "#t") return null;
  return hashParam[1];
};

function removeVerifyTokenFromUrl() {
  const url = new URL(window.location.href);
  url.hash = "";
  url.searchParams.delete("verify");
  window.history.replaceState({}, "", url.toString());
}

export const onAuthStateChange = async (
  onEvent: (event: AuthEvent) => void,
  currentUrl: string = window.location.href,
) => {
  const verifyToken = readVerifyToken(currentUrl);
  if (verifyToken) {
    removeVerifyTokenFromUrl();
    const res = await verify({ verifyToken });
    if (isOk(res)) {
      saveSessionTokens(res.value);
    }
  }

  const session = readSessionTokens();
  if (session === null) {
    onEvent({ type: "not-signed" });
    return;
  }

  onEvent({
    type: "signed-in",
    authProvider: createAuthProvider(session, saveSessionTokens, () => {
      localStorage.removeItem(storageSessionTokensKey);
      onEvent({ type: "signed-out" });
    }),
  });
};
