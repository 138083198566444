import type { Task, TaskId } from "./task";
import { mockUserId } from "./user.mock";

export const mockTaskId = "mock-task-id" as TaskId;
export const mockTask: Task = {
  id: mockTaskId,
  name: "Mock Task",
  author: mockUserId,
  status: "todo",
  createdAt: new Date("2021-01-01T00:00:00Z"),
  updatedAt: new Date("2021-01-01T00:00:00Z"),
};

export const mockDoneId = "mock-task-id2" as TaskId;
export const mockDoneTask: Task = {
  id: mockDoneId,
  name: "Mock Task 2",
  author: mockUserId,
  assignee: mockUserId,
  status: "done",
  createdAt: new Date("2021-01-01T00:00:00Z"),
  updatedAt: new Date("2021-01-01T00:00:00Z"),
};
