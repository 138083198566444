<script lang="ts">
  import TaskList from "../components/TaskList.svelte";
  import DefaultLayout from "../layout/DefaultLayout.svelte";
  import type { SpaceId } from "../model/space";
  import { mockSpaceId } from "../model/space.mock";
  import type { AuthPageContext } from "../page-context.ts";

  export let page: AuthPageContext;
  let spaceId = (page.params.space as SpaceId) ?? mockSpaceId;
</script>

<DefaultLayout>
  <h1>Tasks of {page.user.name ?? page.user.email}</h1>
  <TaskList tasks={page.store.tasks(spaceId)} />
</DefaultLayout>
