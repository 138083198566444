<script lang="ts">
  export let size: "medium" | "small" | "large" = "medium";
  const sizes = {
    small: { width: 26, height: 30 },
    medium: { width: 38, height: 48 },
    large: { width: 104, height: 128 },
  };
</script>

<svg
  clip-rule="evenodd"
  fill-rule="evenodd"
  shape-rendering="geometricPrecision"
  version="1.1"
  viewBox="0 0 260 320"
  width={sizes[size].width}
  height={sizes[size].height}
  xmlns="http://www.w3.org/2000/svg"
>
  <g transform="translate(-60,-46) rotate(15)">
    <path
      fill="#acd1e3"
      d="m243.5 3.5c5.598-0.34428 10.932 0.32239 16 2-5.534-0.16848-10.867-0.83514-16-2z"
    />
    <path
      fill="#f8f7f6"
      d="m196.5 24.5c24.327 2.082 48.66 4.2487 73 6.5 4.491 0.1452 8.824 0.9785 13 2.5-13.033 14.201-25.533 28.867-37.5 44-1.011 0.8366-2.178 1.17-3.5 1-21.622-4.3313-43.289-8.998-65-14-4.966-1.1266-9.966-2.1266-15-3-0.149-1.052 0.017-2.052 0.5-3 2.764-2.9174 5.264-5.9174 7.5-9 9.333-8.0001 18.333-16.334 27-25z"
    />
    <path
      fill="#25435a"
      d="m243.5 3.5c5.133 1.1649 10.466 1.8315 16 2 10.459 0.37875 20.792 1.5454 31 3.5l1.5 1.5c0.206 6.3838 0.706 12.717 1.5 19 6.748 7.7513 14.081 14.751 22 21-1.478 0.262-2.811-0.0713-4-1-2.585-1.9123-4.918-4.079-7-6.5-0.948-0.4828-1.948-0.6495-3-0.5 0.149-1.052-0.017-2.052-0.5-3-4.599-4.6109-8.932-8.6109-13-12-3.621 0.1425-7.455 0.1425-11.5 0v3c2.42-0.2076 4.753 0.1258 7 1 0.438 2.7949 1.438 5.1283 3 7-2.324 3.323-4.991 6.323-8 9-3.209 0.6818-5.875 2.3484-8 5 1.401 1.0207 2.735 2.0207 4 3-3.567 2.0566-6.734 4.7232-9.5 8-2.98 3.8142-6.146 7.4809-9.5 11 18.391-3.5705 37.058-7.0705 56-10.5-5.413-1.6435-10.746-3.1435-16-4.5 1.266-0.5899 2.599-1.0899 4-1.5-0.516-2.0328 0.15-2.0328 2 0-0.333 0.3333-0.667 0.6667-1 1 3.974 0.9961 7.974 1.8294 12 2.5 1.633-1.2442 2.633-0.7442 3 1.5-0.405 0.9454-1.072 1.6121-2 2 2.046 2.6355 4.546 3.1355 7.5 1.5 1.301 0.1387 2.134 0.8054 2.5 2-0.992-0.1716-1.658 0.1618-2 1-20.837 3.7503-41.504 8.2503-62 13.5-2.982 0.498-5.982 0.6646-9 0.5 0.754-2.0053 1.921-2.672 3.5-2l1.5-1.5c-1.829-0.2735-3.329-1.1068-4.5-2.5-1.444 1.6554-3.278 2.322-5.5 2-0.667 2-1.333 4-2 6-9.935-2.3171-19.935-4.3171-30-6-0.111-0.6174-0.444-1.1174-1-1.5 9.55-0.2179 19.217-0.0512 29 0.5 1.322 0.17 2.489-0.1634 3.5-1 11.967-15.133 24.467-29.799 37.5-44-4.176-1.5215-8.509-2.3548-13-2.5-24.34-2.2513-48.673-4.418-73-6.5-1.629-1.5603-2.796-1.227-3.5 1-1.518-0.6634-2.685-1.6634-3.5-3-0.166-5.6764 1e-3 -11.343 0.5-17 0.574-1.992 1.741-3.492 3.5-4.5 16.679 0.27828 33.345 1.1116 50 2.5z"
    />
    <path
      fill="#f8f7f6"
      d="m196.5 7.5c29.264 2.8788 58.598 5.2122 88 7v11c-29.35-2.5193-58.683-5.186-88-8v-10z"
    />
    <path
      fill="#385b6f"
      d="m189.5 22.5c0.815 1.3366 1.982 2.3366 3.5 3 0.704-2.227 1.871-2.5603 3.5-1-8.667 8.6665-17.667 17-27 25v-7c6.667-6.6667 13.333-13.333 20-20z"
    />
    <path
      fill="#acd1e3"
      d="m278.5 47.5c3.009-2.677 5.676-5.677 8-9 0.651-0.5906 1.318-1.2573 2-2 6.555 6.7248 13.555 12.891 21 18.5-8.339-1.4011-16.672-3.0678-25-5-2.352-0.2401-4.352-1.0735-6-2.5z"
    />
    <path
      fill="#25435a"
      d="m315.5 50.5c4.61 4.3091 9.61 8.1424 15 11.5 0.752 0.6708 1.086 1.5041 1 2.5-0.998 32.496-1.332 65.162-1 98-1.373 0.157-2.707-0.01-4-0.5 1.212-1.107 0.879-1.607-1-1.5 0.659-31.018-8e-3 -61.685-2-92-0.366-1.1946-1.199-1.8613-2.5-2-2.954 1.6355-5.454 1.1355-7.5-1.5 0.928-0.3879 1.595-1.0546 2-2-0.367-2.2442-1.367-2.7442-3-1.5-4.026-0.6706-8.026-1.5039-12-2.5 0.333-0.3333 0.667-0.6667 1-1-1.85-2.0328-2.516-2.0328-2 0-1.401 0.4101-2.734 0.9101-4 1.5-7.038-1.1766-14.038-2.51-21-4-1.265-0.9793-2.599-1.9793-4-3 2.125-2.6516 4.791-4.3182 8-5 1.648 1.4265 3.648 2.2599 6 2.5 8.328 1.9322 16.661 3.5989 25 5-7.445-5.6086-14.445-11.775-21-18.5-0.682 0.7427-1.349 1.4094-2 2-1.562-1.8717-2.562-4.2051-3-7-2.247-0.8742-4.58-1.2076-7-1v-3c4.045 0.1425 7.879 0.1425 11.5 0 4.068 3.3891 8.401 7.3891 13 12 0.483 0.948 0.649 1.948 0.5 3 1.052-0.1495 2.052 0.0172 3 0.5 2.082 2.421 4.415 4.5877 7 6.5 1.189 0.9287 2.522 1.262 4 1z"
    />
    <path
      fill="#acd1e3"
      d="m274.5 55.5c6.962 1.49 13.962 2.8234 21 4 5.254 1.3565 10.587 2.8565 16 4.5-18.942 3.4295-37.609 6.9295-56 10.5 3.354-3.5191 6.52-7.1858 9.5-11 2.766-3.2768 5.933-5.9434 9.5-8z"
    />
    <path
      fill="#25435a"
      d="m169.5 42.5v7c-2.236 3.0826-4.736 6.0826-7.5 9-0.483 0.948-0.649 1.948-0.5 3 5.034 0.8734 10.034 1.8734 15 3 1.405 2.1107 3.405 3.6107 6 4.5-7.732 0.5501-15.399 0.0501-23-1.5h-5c-1.627 3.9842-2.794 8.3176-3.5 13-0.915-0.8279-1.581-1.8279-2-3-0.499-4.9889-0.666-9.9889-0.5-15 6.974-6.6389 13.974-13.306 21-20z"
    />
    <path
      fill="#385b6f"
      d="m176.5 64.5c21.711 5.002 43.378 9.6687 65 14-9.783-0.5512-19.45-0.7179-29-0.5 0.556 0.3826 0.889 0.8826 1 1.5-18.126-3.9147-36.126-7.9147-54-12 7.601 1.5501 15.268 2.0501 23 1.5-2.595-0.8893-4.595-2.3893-6-4.5z"
    />
    <path
      fill="#acd1e3"
      d="m321.5 69.5h3c0.332 50.836-1e-3 101.5-1 152h-2c-14.731-4.934-29.731-9.101-45-12.5-3.333-0.667-6.667-0.667-10 0-5.183 1.506-10.35 3.006-15.5 4.5-0.5-43.332-0.667-86.665-0.5-130 3.018 0.1646 6.018-2e-3 9-0.5 20.496-5.2497 41.163-9.7497 62-13.5z"
    />
    <path
      fill="#f8f7f6"
      d="m154.5 67.5h5c17.874 4.0853 35.874 8.0853 54 12 10.065 1.6829 20.065 3.6829 30 6v141 59c-3.39-0.523-6.723-1.357-10-2.5-24.524-10.006-49.19-19.506-74-28.5-1.437-1.225-3.104-2.225-5-3-0.333-0.833-0.667-1.667-1-2.5 0.991-60.481 1.324-120.98 1-181.5z"
    />
    <path
      fill="#5b7d8c"
      d="m321.5 69.5c0.342-0.8382 1.008-1.1716 2-1 1.992 30.315 2.659 60.982 2 92 0.333 20.677 0 41.344-1 62-0.607-0.124-0.94-0.457-1-1 0.999-50.497 1.332-101.16 1-152h-3z"
    />
    <path
      fill="#25435a"
      d="m250.5 83.5c-0.167 43.335 0 86.668 0.5 130 5.15-1.494 10.317-2.994 15.5-4.5 3.333-0.667 6.667-0.667 10 0 15.269 3.399 30.269 7.566 45 12.5 0.104 2.473 1.104 3.139 3 2 0.787 0.742 1.453 1.575 2 2.5-2.778 1.392-5.778 1.892-9 1.5-14.269-3.984-28.603-7.818-43-11.5-5.065-0.258-10.065 0.242-15 1.5-0.617-0.111-1.117-0.444-1.5-1-2.533 1.372-5.2 2.372-8 3-0.499 5.323-0.666 10.656-0.5 16h-4c0.555-3.512-0.112-6.512-2-9v-141c0.667-2 1.333-4 2-6 2.222 0.322 4.056-0.3446 5.5-2 1.171 1.3932 2.671 2.2265 4.5 2.5l-1.5 1.5c-1.579-0.672-2.746-0.0053-3.5 2z"
    />
    <path
      fill="#d7e0e6"
      d="m147.5 64.5v85c-0.43-3.64-1.43-7.14-3-10.5 1.657-24.777 2.657-49.611 3-74.5z"
    />
    <path
      fill="#25435a"
      d="m153.5 254.5c-0.61 2.35-0.944 2.017-1-1h-3c-0.033-44.139-0.366-88.306-1-132.5 0.167-8.167 0.333-16.333 0.5-24.5 0.667 1.3333 1.333 1.3333 2 0 1.558 18.115 2.391 36.282 2.5 54.5-1.172 34.538-1.172 69.038 0 103.5z"
    />
    <path
      fill="#25435a"
      d="m148.5 62.5c-0.166 5.0111 1e-3 10.011 0.5 15 0.419 1.1721 1.085 2.1721 2 3 0.706-4.6824 1.873-9.0158 3.5-13 0.324 60.519-9e-3 121.02-1 181.5 0.333 0.833 0.667 1.667 1 2.5 1.896 0.775 3.563 1.775 5 3h-6c-1.172-34.462-1.172-68.962 0-103.5-0.109-18.218-0.942-36.385-2.5-54.5-0.667 1.3333-1.333 1.3333-2 0-0.167 8.167-0.333 16.333-0.5 24.5 0.634 44.194 0.967 88.361 1 132.5-0.438 1.006-1.104 1.006-2 0v-104-85c-0.172-0.9916 0.162-1.6583 1-2z"
    />
    <path
      fill="#f8f7f6"
      d="m317.5 227.5c1.648 1.427 3.648 2.26 6 2.5 0.88 1.356 1.214 2.856 1 4.5-11.531 6.047-23.531 10.88-36 14.5-5.991 0.499-11.991 0.666-18 0.5-3.734 0.177-7.401-0.156-11-1-2.808-1.429-5.808-2.095-9-2-0.331-8.692 2e-3 -17.359 1-26 2.884-0.709 5.551-1.709 8-3 4.935-1.258 9.935-1.758 15-1.5 14.397 3.682 28.731 7.516 43 11.5z"
    />
    <path
      fill="#25435a"
      d="m331.5 64.5c0.996 57.595 1.329 115.26 1 173 0.166 5.344-1e-3 10.677-0.5 16-0.93 2.052-2.43 3.552-4.5 4.5-26.586 11.621-53.252 23.288-80 35-1.667 0.667-3.333 0.667-5 0-15.056-5.407-30.056-10.907-45-16.5-15.597-6.124-31.263-12.29-47-18.5-1.641-1.129-2.641-2.629-3-4.5 0.896 1.006 1.562 1.006 2 0h3c0.056 3.017 0.39 3.35 1 1h6c24.81 8.994 49.476 18.494 74 28.5 3.277 1.143 6.61 1.977 10 2.5v-59c1.888 2.488 2.555 5.488 2 9h4c-0.166-5.344 1e-3 -10.677 0.5-16 2.8-0.628 5.467-1.628 8-3 0.383 0.556 0.883 0.889 1.5 1-2.449 1.291-5.116 2.291-8 3-0.998 8.641-1.331 17.308-1 26 3.192-0.095 6.192 0.571 9 2-2.107 0.968-4.441 1.302-7 1v3c-1.328 4.788-1.995 9.954-2 15.5 0.333 5.167 0.667 10.333 1 15.5 23.805-11.238 47.805-21.905 72-32 0.992-4.283 1.326-8.616 1-13v-4c0.214-1.644-0.12-3.144-1-4.5-2.352-0.24-4.352-1.073-6-2.5 3.222 0.392 6.222-0.108 9-1.5-0.547-0.925-1.213-1.758-2-2.5-1.896 1.139-2.896 0.473-3-2h2c0.06 0.543 0.393 0.876 1 1 1-20.656 1.333-41.323 1-62 1.879-0.107 2.212 0.393 1 1.5 1.293 0.49 2.627 0.657 4 0.5-0.332-32.838 2e-3 -65.504 1-98z"
    />
    <path
      fill="#acd1e3"
      d="m324.5 238.5c0.326 4.384-8e-3 8.717-1 13-24.195 10.095-48.195 20.762-72 32-0.333-5.167-0.667-10.333-1-15.5 5e-3 -5.546 0.672-10.712 2-15.5 1.542 0.801 3.208 1.134 5 1 23.911 2.619 46.244-2.381 67-15z"
    />
    <path
      fill="#5b7d8c"
      d="m259.5 248.5c3.599 0.844 7.266 1.177 11 1 4.482 0.826 9.149 1.326 14 1.5-8.81 2.303-17.81 3.136-27 2.5-1.792 0.134-3.458-0.199-5-1v-3c2.559 0.302 4.893-0.032 7-1z"
    />
    <path
      fill="#7197a8"
      d="m324.5 234.5v4c-20.756 12.619-43.089 17.619-67 15 9.19 0.636 18.19-0.197 27-2.5-4.851-0.174-9.518-0.674-14-1.5 6.009 0.166 12.009-1e-3 18-0.5 12.469-3.62 24.469-8.453 36-14.5z"
    />
    <path
      fill="#acd1e3"
      d="m332.5 237.5c5.227 1.472 10.56 2.639 16 3.5 4.742 1.428 9.075 3.594 13 6.5-0.121 3.372-1.788 6.205-5 8.5-7.442 3.221-14.442 7.221-21 12-23.058 10.36-46.058 21.026-69 32-22.277-1.452-43.277-7.618-63-18.5-2.442-1.324-4.442-2.991-6-5 14.944 5.593 29.944 11.093 45 16.5 1.667 0.667 3.333 0.667 5 0 26.748-11.712 53.414-23.379 80-35 2.07-0.948 3.57-2.448 4.5-4.5 0.499-5.323 0.666-10.656 0.5-16z"
    />
  </g>
</svg>
