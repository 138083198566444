import {
  base64UriToBinary,
  binaryToBase64Uri,
  binaryToString,
  stringToBinary,
} from "./encoding.ts";
import type { ErrorKey, Result, ResultAsync } from "./result.ts";
import { err, fromPromise, isErr, ok } from "./result.ts";

export type JwtToken = string;
type PayloadExt = Record<string, string | number | boolean>;
export type JwtPayload<T extends PayloadExt = {}> = {
  /** Issuer */
  iss?: string;

  /** Subject */
  sub?: string;

  /** Audience */
  aud?: string;

  /** Expiration Time */
  exp?: number;

  /** Not Before */
  nbf?: number;

  /** Issued At */
  iat?: number;

  /** JWT ID */
  jti?: string;
} & T;

const jwtAlgorithm: HmacImportParams = {
  name: "HMAC",
  hash: { name: "SHA-256" },
};
const jwtAlgorithmKey = "HS256";

const defaultExpirationTime = 3600;
const getCurrentTime = () => Math.floor(Date.now() / 1000);

export type JwtSecret = string;

export const createJwtKey = (secret: JwtSecret): JsonWebKey => ({
  kty: "oct",
  k: secret,
  alg: jwtAlgorithmKey,
  key_ops: ["sign", "verify"],
  ext: true,
});

export const jwtSign = async <T extends PayloadExt = {}>(
  payload: JwtPayload<T>,
  jsonKey: JsonWebKey,
): Promise<JwtToken> => {
  if (!payload.iat) payload.iat = getCurrentTime();
  if (!payload.exp) {
    payload.exp = getCurrentTime() + defaultExpirationTime;
  }

  const payloadAsJSON = JSON.stringify(payload);
  const partialToken = `${binaryToBase64Uri(
    stringToBinary(JSON.stringify({ type: "JWT", alg: jwtAlgorithmKey })),
  )}.${binaryToBase64Uri(stringToBinary(payloadAsJSON))}`;

  const key = await crypto.subtle.importKey(
    "jwk",
    jsonKey,
    jwtAlgorithm,
    false,
    ["sign"],
  );
  const signature = await crypto.subtle.sign(
    jwtAlgorithm,
    key,
    stringToBinary(partialToken),
  );

  return `${partialToken}.${binaryToBase64Uri(new Uint8Array(signature))}`;
};

const decodePayload = (raw: string): JwtPayload | null => {
  try {
    return JSON.parse(binaryToString(base64UriToBinary(raw)));
  } catch {
    return null;
  }
};

export const jwtParse = <T extends PayloadExt = {}>(
  token: JwtToken,
): Result<JwtPayload<T>, ErrorKey> => {
  const tokenParts = token.split(".");

  if (tokenParts.length !== 3) {
    return err("PARSE_ERROR");
  }

  const payload = decodePayload(tokenParts[1]);

  if (!payload) {
    return err("PARSE_ERROR");
  }

  return ok(payload as JwtPayload<T>);
};

export const jwtVerify = async <T extends PayloadExt = {}>(
  token: JwtToken,
  jsonKey: JsonWebKey,
  currentTime = getCurrentTime(),
): ResultAsync<JwtPayload<T>, ErrorKey> => {
  const payloadRes = jwtParse(token);
  if (isErr(payloadRes)) {
    return payloadRes;
  }
  const tokenParts = token.split(".");

  if (tokenParts.length !== 3) {
    return err("PARSE_ERROR");
  }

  const payload = decodePayload(tokenParts[1]);

  if (!payload) {
    return err("PARSE_ERROR");
  }

  if (payload.nbf && payload.nbf > currentTime) {
    return err("NOT_YET_VALID");
  }

  if (payload.exp && payload.exp <= currentTime) {
    return err("EXPIRED");
  }
  const jwkImportResult = await fromPromise(
    crypto.subtle.importKey("jwk", jsonKey, jwtAlgorithm, false, ["verify"]),
    () => "CRYPTO_LIBRARY_IMPORT_KEY_ERROR",
  );
  if (isErr(jwkImportResult)) {
    return jwkImportResult;
  }

  const jwtVerifyResult = await fromPromise<boolean, ErrorKey>(
    crypto.subtle.verify(
      jwtAlgorithm,
      jwkImportResult.value,
      base64UriToBinary(tokenParts[2]),
      stringToBinary(`${tokenParts[0]}.${tokenParts[1]}`),
    ),
    () => "CRYPTO_LIBRARY_ERROR",
  );
  if (isErr(jwtVerifyResult)) {
    return jwtVerifyResult;
  }
  if (!jwtVerifyResult.value) {
    return err("INVALID_SIGNATURE");
  }
  return ok(payload as JwtPayload<T>);
};

export const generateJwtSecret = (): Promise<JsonWebKey> =>
  crypto.subtle
    .generateKey(jwtAlgorithm, true, ["sign", "verify"])
    .then((key) => crypto.subtle.exportKey("jwk", key));
