import type { AuthTokenProvider } from "../model/session.ts";
import type { InitData } from "../page-context.ts";

import type { ApiResult } from "./utils.ts";
import { apiRequest } from "./utils.ts";

export const fetchInitData = (
  authProvider: AuthTokenProvider,
): ApiResult<InitData> =>
  apiRequest("data", {
    method: "GET",
    body: undefined,
    authProvider,
  });
