import { fromPromise, isErr, ok } from "../../packages/utils/result.ts";
import type {
  RefreshToken,
  SpaceSessionToken,
  UserSessionToken,
  UserTokenProvider,
} from "../model/session.ts";
import type { SpaceId } from "../model/space.ts";

import {
  apiRequest,
  apiRequestEmptyResponse,
  type ApiResult,
  handleFetchErrors,
} from "./utils.ts";

export type SignupRequest = {
  email: string;
};

export const signup = async (request: SignupRequest): ApiResult<void> => {
  const responseResult = await fromPromise(
    fetch(`api/signup`, {
      method: "POST",
      body: JSON.stringify(request),
      headers: { "Content-Type": "application/json" },
      redirect: "follow",
    }),
    handleFetchErrors,
  );
  if (isErr(responseResult)) return responseResult;
  const response = responseResult.value;

  // verify is returned immediately in dev env
  if (response.status === 200) {
    window.location.href = (await response.json()).link;
  }

  return ok(undefined);
};

export type VerifyRequest = {
  verifyToken: RefreshToken;
};

export type UserSessionResponse = {
  refreshToken: RefreshToken;
  sessionToken: UserSessionToken;
};

export const verify = (
  request: VerifyRequest,
): ApiResult<UserSessionResponse> =>
  apiRequest("verify", {
    method: "POST",
    body: request,
  });

export type UserSessionRequest = {
  refreshToken: RefreshToken;
};

export const refreshSession = (
  request: UserSessionRequest,
): ApiResult<UserSessionResponse> =>
  apiRequest("session", {
    method: "POST",
    body: request,
  });

export const deleteSession = (): ApiResult<void> =>
  apiRequestEmptyResponse("session", {
    method: "DELETE",
    body: undefined,
  });

export const createSpaceSession = (
  space: SpaceId,
  authProvider: UserTokenProvider,
): ApiResult<SpaceSessionToken> =>
  apiRequest(`spaces/${space}/sessions`, {
    method: "POST",
    body: undefined,
    authProvider,
  });
