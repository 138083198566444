import type { EpochTimestamp } from "@duodo/utils/time.ts";
import { newTimestamp } from "@duodo/utils/time.ts";
import { createUuidBase64 } from "@duodo/utils/uuid.ts";
import type { Callback } from "linki";

import type { Task, TaskId } from "./task";
import type { UserId } from "./user";

export type TaskEvent = { id: TaskId } & (
  | {
      type: "create";
      name: string;
      author: UserId;
      createdAt: EpochTimestamp;
      updatedAt: EpochTimestamp;
      before?: TaskId;
    }
  | { type: "update-name"; name: string; updatedAt: EpochTimestamp }
  | { type: "assign"; author: UserId; updatedAt: EpochTimestamp }
  | { type: "done"; updatedAt: EpochTimestamp }
  | { type: "todo"; updatedAt: EpochTimestamp }
  | { type: "move"; before: TaskId }
);

export const updateTask = (task: Task, event: TaskEvent): Task => {
  switch (event.type) {
    case "create":
      return {
        ...task,
        name: event.name,
        author: event.author,
        before: event.before,
      };
    case "update-name":
      return { ...task, name: event.name };
    case "assign":
      return { ...task, assignee: event.author };
    case "done":
      return { ...task, status: "done" };
    case "todo":
      return { ...task, status: "todo" };
    case "move":
      return { ...task, before: event.before };
  }
};

const appendListInOrder = <ID, T extends { before?: ID; id: ID }>(
  item: T,
  list: T[],
): T[] => {
  const oldIndex = list.findIndex((t) => t.id === item.id);
  const newIndex = item.before
    ? list.findIndex((t) => t.id === item.before)
    : -1;

  if (oldIndex >= 0) {
    if (newIndex < 0 || oldIndex === newIndex) {
      list[oldIndex] = item;
      return list;
    } else {
      list.splice(oldIndex, 1);
    }
  }
  if (newIndex < 0) {
    list.push(item);
  } else {
    list.splice(newIndex, 0, item);
  }
  return list;
};

export type TasksStore = {
  subscribe: (callback: Callback<Task[]>) => () => void;
  handle: (event: TaskEvent) => void;
  create: (name: string) => TaskId;
};

export const createInMemoryTaskStore = (
  author: UserId,
  initTasks: Task[],
): TasksStore => {
  const taskList: Task[] = [];
  const taskMap = new Map<TaskId, Task>();

  initTasks.forEach((task) => {
    appendListInOrder(task, taskList);
    taskMap.set(task.id, task);
  });

  const taskSubscriptions: Callback<Task[]>[] = [];
  const updateSubscribers = () => {
    taskSubscriptions.forEach((cb) => cb(taskList));
  };

  const handle = (event: TaskEvent) => {
    const id = event.id;
    const task = taskMap.get(id) ?? ({ id } as Task);
    const newTask = updateTask(task, event);
    taskMap.set(id, newTask);
    appendListInOrder(newTask, taskList);
    updateSubscribers();
  };

  return {
    subscribe: (push) => {
      taskSubscriptions.push(push);
      push(taskList);

      return () => {
        const index = taskSubscriptions.indexOf(push);
        if (index !== -1) {
          taskSubscriptions.splice(index, 1);
        }
      };
    },
    handle,
    create: (name) => {
      const id = createUuidBase64() as TaskId;
      handle({
        id,
        type: "create",
        name,
        author,
        createdAt: newTimestamp(),
        updatedAt: newTimestamp(),
      });
      return id;
    },
  };
};
