<script lang="ts">
  import { createEventDispatcher, tick } from "svelte";

  let taskName = "";
  let creating = false;
  let nameElement: HTMLInputElement;
  const dispatch = createEventDispatcher<{ create: { name: string } }>();

  const startTask = async () => {
    creating = true;
    await tick();
    nameElement?.focus();
  };

  const finishCreate = () => {
    if (taskName === "") return;
    dispatch("create", { name: taskName });
    creating = false;
    taskName = "";
  };
</script>

{#if creating}
  <input
    bind:this={nameElement}
    bind:value={taskName}
    on:change={finishCreate}
    on:keydown={(e) => e.key === "Enter" && finishCreate()}
    type="text"
    placeholder="Task name"
  />
{:else}
  <button on:click={startTask}>Create task</button>
{/if}

<style>
  button {
    width: 100%;
  }
</style>