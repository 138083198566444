import {
  createJwtKey,
  jwtParse,
  jwtSign,
  jwtVerify,
} from "../../packages/utils/jwt.ts";
import type {
  ErrorKey,
  Result,
  ResultAsync,
} from "../../packages/utils/result.ts";
import { isErr, ok } from "../../packages/utils/result.ts";

import type { SpaceId, SpaceRole } from "./space.ts";
import type { UserId } from "./user.ts";

export type RefreshToken = string;
export type SessionId = string;

export type UserSessionToken = string;
export type UserSession = {
  user: UserId;
  session: SessionId;
  expires: Date;
  created: Date;
  token: UserSessionToken;
};
export type UserSessionTokens = {
  sessionToken: UserSessionToken;
  refreshToken: RefreshToken;
};

type UserSessionJwtParams = {
  sub: UserId;
  session: SessionId;
};

export const isExpired = (session: UserSession, now = Date.now()): boolean =>
  session.expires.getTime() < now;

export const REFRESH_HEADS_UP_TIME = 1000 * 60 * 5; // 5 minutes
export const isAboutToExpire = (
  session: UserSession,
  headsup: number = REFRESH_HEADS_UP_TIME,
  now = Date.now(),
): boolean => session.expires.getTime() - now < headsup;

export const generateUserSessionToken = async (
  user: UserId,
  session: SessionId,
  sessionDuration: number,
  jwtSecret: string,
): Promise<UserSessionToken> => {
  const created = new Date();
  const expires = new Date(created.getTime());
  expires.setSeconds(created.getSeconds() + sessionDuration);

  return await jwtSign<UserSessionJwtParams>(
    {
      sub: user,
      session,
      iat: Math.floor(created.getTime() / 1000),
      exp: Math.floor(expires.getTime() / 1000),
    },
    createJwtKey(jwtSecret),
  );
};

export const verifyUserSessionToken = async (
  token: UserSessionToken,
  jwtSecret: string,
): ResultAsync<UserSession, ErrorKey> => {
  const payload = await jwtVerify<UserSessionJwtParams>(
    token,
    createJwtKey(jwtSecret),
  );
  if (isErr(payload)) {
    return payload;
  }
  const value = payload.value;

  return ok({
    token,
    user: value.sub,
    session: value.session,
    created: new Date(value.iat! * 1000),
    expires: new Date(value.exp! * 1000),
  });
};

export const readUserSessionToken = (
  token: UserSessionToken,
): Result<UserSession, ErrorKey> => {
  const payload = jwtParse<UserSessionJwtParams>(token);
  if (isErr(payload)) {
    return payload;
  }
  const value = payload.value;

  return ok({
    token,
    user: value.sub,
    session: value.session,
    created: new Date(value.iat! * 1000),
    expires: new Date(value.exp! * 1000),
  });
};

export type SpaceSessionToken = string;
export type SpaceSession = UserSession & {
  space: SpaceId;
  role: SpaceRole;
};

type SpaceSessionJwtParams = UserSessionJwtParams & {
  space: SpaceId;
  role: SpaceRole;
};

export const generateSpaceSessionToken = async (
  session: SessionId,
  user: UserId,
  space: SpaceId,
  role: SpaceRole,
  sessionDuration: number,
  jwtSecret: string,
): Promise<SpaceSessionToken> => {
  const created = new Date();
  const expires = new Date(created.getTime());
  expires.setSeconds(created.getSeconds() + sessionDuration);

  return await jwtSign<SpaceSessionJwtParams>(
    {
      sub: user,
      session,
      space,
      role,
      iat: Math.floor(created.getTime() / 1000),
      exp: Math.floor(expires.getTime() / 1000),
    },
    createJwtKey(jwtSecret),
  );
};

export const verifySpaceSessionToken = async (
  token: SpaceSessionToken,
  jwtSecret: string,
): ResultAsync<SpaceSession, ErrorKey> => {
  const payload = await jwtVerify<SpaceSessionJwtParams>(
    token,
    createJwtKey(jwtSecret),
  );
  if (isErr(payload)) {
    return payload;
  }
  const value = payload.value;

  return ok({
    token,
    user: value.sub,
    session: value.session,
    created: new Date(value.iat! * 1000),
    expires: new Date(value.exp! * 1000),
    space: value.space,
    role: value.role,
  });
};

export const readSpaceSessionToken = (
  token: SpaceSessionToken,
): Result<SpaceSession, ErrorKey> => {
  const payload = jwtParse<SpaceSessionJwtParams>(token);
  if (isErr(payload)) {
    return payload;
  }
  const value = payload.value;

  return ok({
    token,
    user: value.sub,
    session: value.session,
    created: new Date(value.iat! * 1000),
    expires: new Date(value.exp! * 1000),
    space: value.space,
    role: value.role,
  });
};

export type VerifyToken = string;
export const generateVerifyToken = (
  email: string,
  verifyDuration: number,
  jwtSecret: string,
): Promise<VerifyToken> => {
  const created = new Date();
  const expires = new Date(created.getTime());
  expires.setSeconds(created.getSeconds() + verifyDuration);

  return jwtSign(
    {
      email,
      iat: Math.floor(created.getTime() / 1000),
      exp: Math.floor(expires.getTime() / 1000),
    },
    createJwtKey(jwtSecret),
  );
};

export type VerifyData = { email: string };
export const verifyVerifyToken = async (
  token: VerifyToken,
  jwtSecret: string,
): ResultAsync<VerifyData, ErrorKey> => {
  const payload = await jwtVerify<VerifyData>(token, createJwtKey(jwtSecret));
  if (isErr(payload)) {
    return payload;
  }
  const value = payload.value;

  return ok({
    email: value.email,
  });
};

export type UserTokenProvider = () => ResultAsync<UserSessionToken, ErrorKey>;
export type AuthTokenProvider = {
  (): ResultAsync<UserSessionToken, ErrorKey>;
  (space: SpaceId): ResultAsync<SpaceSessionToken, ErrorKey>;
};
