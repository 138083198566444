import "./styles/global.css";
import "./styles/spacing.css";
import { Amplify } from "aws-amplify";

import outputs from "../amplify_outputs.json";
import { isErr } from "../packages/utils/result.ts";

import { fetchInitData } from "./backend/data-fetch.ts";
import type { AppConfig, AppContext, PageContext } from "./page-context.ts";
import { isSignedInAppContext } from "./page-context.ts";
import SignIn from "./pages/SignIn.svelte";
import SignUp from "./pages/SignUp.svelte";
import Space from "./pages/Space.svelte";
import { onAuthStateChange } from "./system/auth.ts";
import { createInMemoryDatastore } from "./system/datastore.ts";
import { createRouter } from "./system/router";

Amplify.configure(outputs);

const config: AppConfig = {
  baseUrl: "http://localhost:5173",
};

const redirectIfNotSignedIn = (context: PageContext) => {
  if (!isSignedInAppContext(context)) {
    return "/sign-in";
  }
};

const throwIfNull = <T>(value: T | undefined | null): T => {
  if (value === undefined || value === null) {
    throw new Error("Value is undefined");
  }
  return value;
};

const router = createRouter<AppContext>({
  routes: [
    {
      title: "Home",
      path: "/",
      component: Space,
      redirect: redirectIfNotSignedIn,
    },
    {
      title: "Sign In",
      path: "/sign-in",
      component: SignIn,
      redirect: (context) => {
        if (isSignedInAppContext(context)) {
          return "/";
        }
      },
    },
    {
      title: "Sign Up",
      path: "/sign-up",
      component: SignUp,
      redirect: (context) => {
        if (isSignedInAppContext(context)) {
          return "/";
        }
      },
    },
    {
      title: "Tasks",
      path: "/sp/:space",
      component: Space,
      redirect: redirectIfNotSignedIn,
    },
  ],
  default: "/",
  root: throwIfNull(document.getElementById("outlet")),
});

onAuthStateChange((event) => {
  /* eslint-disable no-console */
  console.log("Auth state change", event);

  if (event.type === "signed-in") {
    fetchInitData(event.authProvider).then((result) => {
      if (isErr(result)) {
        console.error(result.error);
        return;
      }
      router.updateContext({
        config,
        store: createInMemoryDatastore(result.value.user.id),
        ...result.value,
      });
    });
  } else {
    router.updateContext({
      config,
    });
  }
});
