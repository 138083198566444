import type { Response as CfResponse } from "@cloudflare/workers-types";
import { ErrorKey } from "@duodo/utils/result";

export const HttpStatus = {
  Ok: 200,
  Created: 201,
  Empty: 204,
  Redirect: 302,
  SeeOther: 303,
  BadRequest: 400,
  Unauthorized: 401,
  Forbidden: 403,
  NotFound: 404,
  InternalServerError: 500,
};

export const emptyResponse = (): CfResponse =>
  new Response(null, { status: HttpStatus.Empty }) as unknown as CfResponse;

export const jsonResponse = <T extends Record<string, unknown>>(
  body: T,
): CfResponse =>
  new Response(JSON.stringify(body), {
    status: HttpStatus.Ok,
    headers: { "Content-Type": "application/json" },
  }) as unknown as CfResponse;

export const textResponse = (body: string): CfResponse =>
  new Response(body, {
    status: HttpStatus.Ok,
    headers: { "Content-Type": "text/plain" },
  }) as unknown as CfResponse;

export const redirectResponse = (
  location: string,
  code: number = HttpStatus.Redirect,
): CfResponse =>
  new Response(null, {
    status: code,
    headers: { Location: location },
  }) as unknown as CfResponse;

export const binaryResponse = (status: number, body: Uint8Array): CfResponse =>
  new Response(body, {
    status,
    headers: { "Content-Type": "application/octet-stream" },
  }) as unknown as CfResponse;

export const badRequestResponse = (errorKey: string): CfResponse =>
  new Response(JSON.stringify({ errorKey }), {
    status: HttpStatus.BadRequest,
  }) as unknown as CfResponse;

export const unauthorizedResponse = (errorKey?: ErrorKey): CfResponse =>
  new Response(errorKey ? JSON.stringify({ errorKey }) : null, {
    status: HttpStatus.Unauthorized,
  }) as unknown as CfResponse;

export const forbiddenResponse = (errorKey?: ErrorKey): CfResponse =>
  new Response(errorKey ? JSON.stringify({ errorKey }) : null, {
    status: HttpStatus.Forbidden,
  }) as unknown as CfResponse;

export const notFoundResponse = (): CfResponse =>
  new Response(null, { status: HttpStatus.NotFound }) as unknown as CfResponse;

export const serverErrorResponse = (): CfResponse =>
  new Response(null, {
    status: HttpStatus.InternalServerError,
  }) as unknown as CfResponse;
