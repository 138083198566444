export type Base64 = string;
export type SafeBase64 = string;
export type HexString = string;

export const base64ToBinary = (base64: Base64): Uint8Array => {
  const binary_string = atob(base64);
  const len = binary_string.length;
  const bytes = new Uint8Array(len);
  for (let i = 0; i < len; i++) {
    bytes[i] = binary_string.charCodeAt(i);
  }
  return bytes;
};

export const binaryToBase64 = (bytes: Uint8Array): Base64 => {
  // @ts-ignore
  return btoa(String.fromCharCode.apply(0, bytes));
};

export const base64ToBase64Uri = (base64: Base64): SafeBase64 =>
  base64.replace(/=/g, "").replace(/\+/g, "-").replace(/\//g, "_");

export const base64UriToBase64 = (base64: SafeBase64): Base64 =>
  base64.replace(/-/g, "+").replace(/_/g, "/");

export const hexToBinary = (hex: HexString): Uint8Array => {
  const bytes = new Uint8Array(hex.length / 2);
  for (let i = 0; i < hex.length; i += 2) {
    bytes[i / 2] = parseInt(hex.slice(i, i + 2), 16);
  }
  return bytes;
};

const DIGITS = "0123456789abcdef";

export const binaryToHex = (buff: ArrayBuffer): HexString => {
  const hexOctets = [];
  const bytes = new Uint8Array(buff);
  for (let i = 0; i < bytes.length; ++i)
    hexOctets.push(
      DIGITS.charAt(bytes[i] >>> 4) + DIGITS.charAt(bytes[i] & 0xf),
    );
  return hexOctets.join("");
};
export const base64UriToBinary = (s: SafeBase64): BinaryString =>
  base64ToBinary(base64UriToBase64(s));

export const binaryToBase64Uri = (data: BinaryString): SafeBase64 =>
  base64ToBase64Uri(binaryToBase64(data));

const decoder = new TextDecoder();
const encoder = new TextEncoder();

export type BinaryString = Uint8Array;
export const stringToBinary = (str: string): BinaryString =>
  encoder.encode(str);
export const binaryToString = (data: BinaryString): string =>
  decoder.decode(data);

export type Json = string | number | boolean | object;
export const jsonToBinary = (json: Json): BinaryString =>
  stringToBinary(jsonToString(json));
export const jsonToString = (json: Json): string => JSON.stringify(json);

export const binaryToJson = (data: BinaryString): Json =>
  stringToJson(binaryToString(data));

export const stringToJson = (data: string): Json => JSON.parse(data);
