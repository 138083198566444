import type { SpaceLabel } from "./model/space.ts";
import type { User } from "./model/user";
import type { Datastore } from "./system/datastore";
import type { RoutingContext } from "./system/router";

export type InitData = {
  user: User;
  spaces: SpaceLabel[];
};

export type AppConfig = {
  baseUrl: string;
};
export type AnonymousAppContext = {
  config: AppConfig;
};

export type SignedInAppContext = AnonymousAppContext &
  InitData & {
    store: Datastore;
  };

export const isSignedInAppContext = (
  context: AppContext,
): context is SignedInAppContext => {
  return (context as SignedInAppContext).user !== undefined;
};

export type AppContext = AnonymousAppContext | SignedInAppContext;
export type PageContext = RoutingContext<AppContext>;
export type AuthPageContext = RoutingContext<SignedInAppContext>;
