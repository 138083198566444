<script lang="ts">
  import type { TasksStore } from "../model/task-store";

  import Task from "./Task.svelte";
  import TaskCreator from "./TaskCreator.svelte";

  export let tasks: TasksStore;
</script>

<div class="stack">
  <ul class="stack" style="list-style: none">
    {#each $tasks as task}
      <li><Task {task} on:update={(e) => tasks.handle(e.detail)} /></li>
    {/each}
  </ul>
  <TaskCreator on:create={(e) => tasks.create(e.detail.name)} />
</div>
