/* eslint-disable @typescript-eslint/no-explicit-any */
export type Ok<T> = {
  readonly value: T;
};
export type Err<E> = {
  readonly error: E;
};
export type Result<T, E = Error> = Ok<T> | Err<E>;
export type ErrorKey = string;
export type ValidationError = {
  key: ErrorKey;
  details?: string;
};
export const validationError = (
  key: string,
  details?: string,
): ValidationError => (details ? { key, details } : { key });

export const ok = <T>(value: T): Ok<T> => ({ value });
export const err = <E>(error: E): Err<E> => ({ error });

export const isOk = <T, E>(result: Result<T, E>): result is Ok<T> => {
  return "value" in result;
};
export const isErr = <T, E>(result: Result<T, E>): result is Err<E> => {
  return "error" in result;
};

export type ResultAsync<T, E = Error> = Promise<Result<T, E>>;

export const fromPromise = <T, E>(
  promise: Promise<T>,
  createError: (err: unknown) => E,
): ResultAsync<T, E> => {
  return promise.then(ok).catch((error) => err(createError(error)));
};

export const fromThrowable =
  <T, E>(fn: (...args: any[]) => T, createError: (err: unknown) => E) =>
  (...args: any[]): Result<T, E> => {
    try {
      return ok(fn(...args));
    } catch (error) {
      return err(createError(error));
    }
  };

export const mapResult = <T, U, E>(
  result: Result<T, E>,
  fn: (value: T) => U,
): Result<U, E> => {
  return isOk(result) ? ok(fn(result.value)) : result;
};

export const mapResultAsync = async <T, U, E>(
  result: ResultAsync<T, E>,
  fn: (value: T) => U | ResultAsync<U, E>,
): ResultAsync<U, E> => {
  const resultAwaited = await result;
  if (isErr(resultAwaited)) return resultAwaited;
  const mappedResult = fn(resultAwaited.value);
  if (mappedResult instanceof Promise) return mappedResult;
  return ok(mappedResult);
};

export const allValid = <T, E>(results: Result<T, E>[]): Result<T[], E> => {
  const correct: T[] = new Array(results.length);
  for (let i = 0; i < results.length; i++) {
    const result = results[i];
    if (isErr(result)) return result as Result<T[], E>;
    correct[i] = result.value;
  }
  return ok(correct);
};

export const throwIfError = <T, E>(result: Result<T, E>): T => {
  if (isErr(result))
    throw result.error instanceof Error
      ? result.error
      : new Error(JSON.stringify(result.error));
  return result.value;
};

export const throwIfValue = <T, E>(result: Result<T, E>): E => {
  if (isOk(result)) throw result.value;
  return result.error;
};

export const resultFallback = <T, E>(
  result: Result<T, E>,
  fallbackValue: T,
): T => {
  return isOk(result) ? result.value : fallbackValue;
};
